export const data = {
  sitemapTitle: "상품 - 카카오 i 커넥트 라이브",
  title:
    "실시간 상호작용(Interactive)이<br>가능한 라이브스트리밍 SDK를 활용해<br>멋진 서비스를 만들어보세요.",
  description:
    "카카오 i 커넥트 라이브는 통화, 방송, 회의, 하이브리드 서비스를 만들 수 있는<br>클라우드 인프라와 SDK를 제공합니다.",
  spec: {
    title: "지원 플랫폼과 스펙을 확인하세요",
    web: {
      title: "Web Browser",
      description:
        "Chrome(PC,Android), Safari(PC, Mobile), Microsoft Edge, Firefox"
    },
    mobile: {
      title: "Mobile OS",
      description: "Android 5.0 or higher, iOS 11 or higher"
    }
  },
  livecall: {
    name: "livecall",
    platform: {
      title: "Live Call을 이용해 모바일 앱에 통화기능을 추가해보세요",
      description:
        "앱에서 이탈하지 않는 새로운 사용자 경험을 만들 수 있습니다. 교육, 배달, 중고거래 등 다양한 분야에서 사용할 수 있습니다.",
      list: [
        {
          description:
            "직접 구축할 필요 없이 빠르게<br> 개발할 수 있는 통화 플랫폼"
        },
        {
          description:
            "카메라/마이크 디바이스 관리,<br> 연결 유지 등을 위한 기능 제공"
        },
        {
          description: "미디어서버 모니터링과<br> 안정성은 기본"
        }
      ]
    },
    service: {
      list: [
        {
          title: "음성통화, 영상통화",
          description:
            "모바일 앱에 통화기능을 추가하여 새로운 사용자 경험을 만들 수 있습니다."
        },
        {
          title: "소규모 다자간 통화",
          description:
            "Live Conference 보다 저렴한 가격으로 5인<br> 이하의 소규모 다자간 통화를 구현할 수<br class='lg-pc-only'> 있습니다."
        },
        {
          title: "1:1 텍스트 및 이미지 채팅",
          description:
            "텍스트와 이미지를 포함한 단순한 채팅을<br> 만들 수 있습니다."
        },
        {
          title: "녹음 및 녹화",
          description:
            "서버에서 녹음하지 않고, 클라이언트에서<br class='lg-pc-only'> 통화를 녹음/녹화할 수 있습니다."
        },
        {
          title: "Admin API 지원",
          description:
            "관리자가 진행 중인 방송을 조회하고 강제로 종료할 수 있습니다."
        },
        {
          title: "웹 콘솔 제공",
          description:
            "손쉽게 통화량을 조회하고 오류를 찾아<br class='lg-pc-only'> 실시간<br class='hide-lg'> 수정이 가능합니다."
        }
      ]
    },
    subject: {
      title: "다양한 분야에서 활용하세요.",
      list: [
        {
          title: "전화 영어 서비스",
          description:
            "앱은 예약만 하고 수업은 다른 서비스를<br class='lg-pc-only'>이용하시나요?<br class='hide-lg'> 앱에 통화를 넣어보세요."
        },
        {
          title: "전문가 상담 서비스",
          description:
            "전문가와 고객의 시간은 비용과 직결됩니다.<br> 앱에 통화를 넣어보세요."
        },
        {
          title: "미팅 어플",
          description:
            "매끄럽고 자연스러운 서비스 경험을 위해, 앱에<br class='lg-pc-only'> 통화를<br class='hide-lg'> 넣어보세요."
        }
      ]
    }
  },
  livecast: {
    name: "livecast",
    platform: {
      title: "Live Cast는 방송과 시청을 모두 해결합니다",
      description:
        "스튜디오, 플레이어, 인코딩/트랜스코딩 등을 따로 구매할 필요없이 Connect Live SDK 하나면 됩니다",
      list: [
        {
          description: "낮은 레이턴시로 만나는,<br> 진짜 실시간 대화형 서비스",
          guideText: "*경쟁 플랫폼은 8초"
        },
        {
          description: "클라우드 서비스로<br> 초기 비용에 대한 부담없이 시작"
        },
        {
          description: "많은 시청자가 몰려도<br> 안정적으로 유지되는 플랫폼"
        }
      ]
    },
    service: {
      list: [
        {
          title: "실시간 방송",
          description:
            "1초 미만의 Latency로 방송자와 시청자를<br> 연결합니다. 진정한 실시간 방송을<br class='lg-pc-only'> 제공합니다."
        },
        {
          title: "동시 시청자 1만명",
          description:
            "실시간으로 방송을 전달하면서, 동시 시청자<br> 1만명을 지원합니다."
        },
        {
          title: "다자간 방송 10인",
          description:
            "여러명의 출연자가 원격으로 참여하고, 이를 방송할 수 있습니다."
        },
        {
          title: "녹음 및 녹화",
          description:
            "서버와 클라이이언트에서<br class='lg-pc-only'> 각각 영상을 녹화할 수 있습니다."
        },
        {
          title: "Admin API 지원",
          description:
            "관리자가 진행 중인 방송을 조회하고 강제로 종료할 수 있습니다."
        },
        {
          title: "웹 콘솔 제공",
          description:
            "손쉽게 방송 및 시청량을 조회하고 오류를 찾아 실시간 수정이 가능합니다."
        }
      ]
    },
    subject: {
      title: "다양한 분야에서 활용하세요.",
      list: [
        {
          title: "음성 라디오 서비스",
          description:
            "오디오만 송출도 가능합니다.<br> 여러 사람의 대화를 방송할 수 있습니다."
        },
        {
          title: "1인 방송 서비스",
          description:
            "기존 비즈니스에 화상회의를 더해 업무효율을 높이세요. 이커머스, 퀴즈쇼 어디든 방송을 추가할 수 있습니다"
        },
        {
          title: "웨비나",
          description:
            "최대 만명까지 참여하는, 실시간으로 상호작용하는 웨비나를 만들 수 있습니다."
        }
      ]
    }
  },
  liveconf: {
    name: "liveconf",
    platform: {
      title: "Live Conference로 쉽게 만드세요",
      description:
        "스튜디오, 플레이어, 인코딩/트랜스코딩 등을 따로 구매할 필요없이 Connect Live SDK 하나면 됩니다",
      list: [
        {
          description: "안정적인 연결을 통한<br> 신뢰할 수 있는 화상회의"
        },
        {
          description: "쉬운 디바이스 관리와<br> 연결 유지 등을 위한 기능 제공"
        },
        {
          description: "전세계에서 접속가능한<br> 최적화된 대규모 네트워크"
        }
      ]
    },
    service: {
      list: [
        {
          title: "화상회의, 그룹통화",
          description:
            "WebRTC기술을 이용해 1초 미만의 지연시간으로 원활한 의사소통이 가능합니다."
        },
        {
          title: "동시 회의 인원 200명",
          description: "가장 효율적인 구조로 200명의 참여자를 지원합니다."
        },
        {
          title: "화면공유",
          description:
            "손쉽게 화면을 공유하고 실시간 피드백을<br class='lg-pc-only'> 받을 수 있습니다."
        },
        {
          title: "Admin API 지원",
          description:
            "관리자가 진행 중인 방송을 조회하고 강제로 종료할 수 있습니다."
        },
        {
          title: "웹 콘솔 제공",
          description:
            "현재 사용중인 회의실 정보를 조회하고 오류를 살펴볼 수 있습니다."
        },
        {
          title: "최대 영상 수 49명",
          description:
            "한 화면에 참여자의 영상을 최대 49개까지 확인할 수 있습니다."
        }
      ]
    },
    subject: {
      title: "다양한 분야에서 활용하세요.",
      list: [
        {
          title: "화상회의",
          description:
            "기존 비즈니스에 화상회의를 더해 업무효율을<br class='lg-pc-only'> 높이세요.<br class='hide-lg'> 문서 작성 도구, 이메일에 화상회의를 추가할 수 있습니다."
        },
        {
          title: "방송 서비스",
          description:
            "생방송 퀴즈쇼 등 원격의 참여자들이 서로<br class='lg-pc-only'> 대화하는<br class='hide-lg'> 콘텐츠를 제작하고 방송할 수 있습니다."
        },
        {
          title: "교육 서비스",
          description:
            "일반적인 화상회의로 교육을 하는 것은 불편합니다.<br class='hide-lg'> 교육 환경에 맞는 화상회의를 쉽게 만들 수 있습니다."
        }
      ]
    }
  },
  mobile: {
    title:
      "실시간 상호작용(Interactive)이<br>가능한 라이브스트리밍 SDK를 활용해<br>멋진 서비스를 만들어보세요.",
    description:
      "카카오 i 커넥트 라이브는 통화, 방송, 회의, 하이브리드 서비스를 만들 수 있는<br>클라우드 인프라와 SDK를 제공합니다.",
    spec: {
      title: "지원 플랫폼과<br> 스펙을 확인하세요",
      web: {
        title: "Web Browser",
        description:
          "Chrome(PC,Android), Safari(PC, Mobile),<br class='sm-hide'> Microsoft Edge, Firefox"
      },
      mobile: {
        title: "Mobile OS",
        description: "Android 5.0 or higher, iOS 11 or higher"
      }
    },
    livecall: {
      name: "livecall",
      platform: {
        title: "Live Call을 이용해 모바일 앱에 통화기능을 추가해보세요",
        description:
          "앱에서 이탈하지 않는 새로운 사용자 경험을 만들 수 있습니다. 교육, 배달, 중고거래 등 다양한 분야에서 사용할 수 있습니다.",
        list: [
          {
            description:
              "직접 구축할 필요 없이 빠르게<br> 개발할 수 있는 통화 플랫폼"
          },
          {
            description:
              "카메라/마이크 디바이스 관리,<br class='sm-hide'> 연결 유지 등을 위한 기능 제공"
          },
          {
            description: "미디어서버 모니터링과<br> 안정성은 기본"
          }
        ]
      },
      service: {
        list: [
          {
            title: "음성통화, 영상통화",
            description:
              "모바일 앱에 통화기능을 추가하여 새로운 사용자 경험을 만들 수 있습니다."
          },
          {
            title: "소규모 다자간 통화",
            description:
              "Live Conference 보다 저렴한 가격으로 5인 이하의 소규모 다자간 통화를 구현할 수 있습니다."
          },
          {
            title: "1:1 텍스트 및 이미지 채팅",
            description:
              "텍스트와 이미지를 포함한 단순한 채팅을 만들 수 있습니다."
          },
          {
            title: "녹음 및 녹화",
            description:
              "서버에서 녹음하지 않고, 클라이언트에서 통화를 녹음/녹화할 수 있습니다."
          },
          {
            title: "Admin API 지원",
            description:
              "관리자가 진행 중인 방송을 조회하고 강제로 종료할 수 있습니다."
          },
          {
            title: "웹 콘솔 제공",
            description:
              "손쉽게 통화량을 조회하고 오류를 찾아 실시간 수정이 가능합니다."
          }
        ]
      },
      subject: {
        title: "다양한 분야에서<br> 활용하세요.",
        list: [
          {
            title: "전화 영어 서비스",
            description:
              "앱은 예약만 하고 수업은 다른 서비스를 이용하시나요? 앱에 통화를 넣어보세요."
          },
          {
            title: "전문가 상담 서비스",
            description:
              "전문가와 고객의 시간은 비용과 직결됩니다. 앱에 통화를 넣어보세요."
          },
          {
            title: "미팅 어플",
            description:
              "매끄럽고 자연스러운 서비스 경험을 위해, 앱에 통화를 넣어보세요."
          }
        ]
      }
    },
    livecast: {
      name: "livecast",
      platform: {
        title: "Live Cast는 방송과 시청을 모두 해결합니다",
        description:
          "스튜디오, 플레이어, 인코딩/트랜스코딩 등을 따로 구매할 필요없이 Connect Live SDK 하나면 됩니다",
        list: [
          {
            description:
              "낮은 레이턴시로 만나는,<br> 진짜 실시간 대화형 서비스",
            guideText: "*경쟁 플랫폼은 8초"
          },
          {
            description:
              "클라우드 서비스로<br class='sm-hide'> 초기 비용에 대한 부담없이 시작"
          },
          {
            description: "많은 시청자가 몰려도<br> 안정적으로 유지되는 플랫폼"
          }
        ]
      },
      service: {
        list: [
          {
            title: "실시간 방송",
            description:
              "1초 미만의 Latency로 방송자와 시청자를 연결합니다. 진정한 실시간 방송을 제공합니다."
          },
          {
            title: "동시 시청자 1만명",
            description:
              "실시간으로 방송을 전달하면서, 동시 시청자 1만명을 지원합니다."
          },
          {
            title: "다자간 방송 10인",
            description:
              "여러명의 출연자가 원격으로 참여하고, 이를 방송할 수 있습니다."
          },
          {
            title: "녹음 및 녹화",
            description:
              "서버와 클라이이언트에서 각각 영상을 녹화할 수 있습니다."
          },
          {
            title: "Admin API 지원",
            description:
              "관리자가 진행 중인 방송을 조회하고 강제로 종료할 수 있습니다."
          },
          {
            title: "웹 콘솔 제공",
            description:
              "손쉽게 방송 및 시청량을 조회하고 오류를 찾아 실시간 수정이 가능합니다."
          }
        ]
      },
      subject: {
        title: "다양한 분야에서<br> 활용하세요.",
        list: [
          {
            title: "음성 라디오 서비스",
            description:
              "오디오만 송출도 가능합니다. 여러 사람의 대화를 방송할 수 있습니다."
          },
          {
            title: "1인 방송 서비스",
            description:
              "기존 비즈니스에 화상회의를 더해 업무효율을 높이세요. 이커머스, 퀴즈쇼 어디든 방송을 추가할 수 있습니다"
          },
          {
            title: "웨비나",
            description:
              "최대 만명까지 참여하는, 실시간으로 상호작용하는 웨비나를 만들 수 있습니다."
          }
        ]
      }
    },
    liveconf: {
      name: "liveconf",
      platform: {
        title: "Live Conference로 쉽게 만드세요",
        description:
          "스튜디오, 플레이어, 인코딩/트랜스코딩 등을 따로 구매할 필요없이 Connect Live SDK 하나면 됩니다",
        list: [
          {
            description: "안정적인 연결을 통한<br> 신뢰할 수 있는 화상회의"
          },
          {
            description:
              "쉬운 디바이스 관리와<br class='sm-hide'> 연결 유지 등을 위한 기능 제공"
          },
          {
            description: "전세계에서 접속가능한<br> 최적화된 대규모 네트워크"
          }
        ]
      },
      service: {
        list: [
          {
            title: "화상회의, 그룹통화",
            description:
              "WebRTC기술을 이용해 1초 미만의 지연시간으로 원활한 의사소통이 가능합니다."
          },
          {
            title: "동시 회의 인원 200명",
            description: "가장 효율적인 구조로 200명의 참여자를 지원합니다."
          },
          {
            title: "화면공유",
            description:
              "손쉽게 화면을 공유하고 실시간 피드백을 받을 수 있습니다."
          },
          {
            title: "Admin API 지원",
            description:
              "관리자가 진행 중인 방송을 조회하고 강제로 종료할 수 있습니다."
          },
          {
            title: "웹 콘솔 제공",
            description:
              "현재 사용중인 회의실 정보를 조회하고 오류를 살펴볼 수 있습니다."
          },
          {
            title: "최대 영상 수 49명",
            description:
              "한 화면에 참여자의 영상을 최대 49개까지 확인할 수 있습니다."
          }
        ]
      },
      subject: {
        title: "다양한 분야에서<br> 활용하세요.",
        list: [
          {
            title: "화상회의",
            description:
              "기존 비즈니스에 화상회의를 더해 업무효율을 높이세요. 문서 작성 도구, 이메일에 화상회의를 추가할 수 있습니다."
          },
          {
            title: "방송 서비스",
            description:
              "생방송 퀴즈쇼 등 원격의 참여자들이 서로 대화하는 콘텐츠를 제작하고 방송할 수 있습니다."
          },
          {
            title: "교육 서비스",
            description:
              "일반적인 화상회의로 교육을 하는 것은 불편합니다. 교육 환경에 맞는 화상회의를 쉽게 만들 수 있습니다."
          }
        ]
      }
    }
  }
};
