<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="263"
    height="53"
    fill="none"
    viewBox="0 0 263 53"
  >
    <path
      fill="#000"
      d="M8.05 43.4H28v7.7H0v-49h8.05v41.3zM40.625 9.45c-.934.933-2.054 1.4-3.36 1.4-1.307 0-2.45-.467-3.43-1.4-.934-.98-1.4-2.123-1.4-3.43s.466-2.427 1.4-3.36c.933-.98 2.076-1.47 3.43-1.47 1.353 0 2.496.49 3.43 1.47.933.933 1.4 2.053 1.4 3.36s-.49 2.45-1.47 3.43zm-7.14 41.65v-35h7.56v35h-7.56zM73.552 16.1h8.26l-13.65 35h-8.75l-13.65-35h8.26l9.8 26.6 9.73-26.6zM91.183 36.82c.56 2.66 1.843 4.713 3.85 6.16 2.006 1.4 4.456 2.1 7.35 2.1 4.013 0 7.023-1.447 9.03-4.34l6.23 3.64c-3.454 5.087-8.564 7.63-15.33 7.63-5.694 0-10.29-1.727-13.79-5.18-3.5-3.5-5.25-7.91-5.25-13.23 0-5.227 1.726-9.59 5.18-13.09 3.453-3.547 7.886-5.32 13.3-5.32 5.133 0 9.333 1.797 12.6 5.39 3.313 3.593 4.97 7.957 4.97 13.09 0 .793-.094 1.843-.28 3.15h-27.86zm-.07-6.16h20.58c-.514-2.847-1.704-4.993-3.57-6.44-1.82-1.447-3.967-2.17-6.44-2.17-2.8 0-5.134.77-7 2.31-1.867 1.54-3.057 3.64-3.57 6.3zM167.135 52.01c-7.373 0-13.487-2.427-18.34-7.28-4.853-4.9-7.28-10.943-7.28-18.13 0-7.187 2.427-13.207 7.28-18.06 4.853-4.9 10.967-7.35 18.34-7.35 4.433 0 8.517 1.05 12.25 3.15 3.78 2.1 6.72 4.947 8.82 8.54l-7 4.06c-1.307-2.427-3.22-4.34-5.74-5.74-2.473-1.447-5.25-2.17-8.33-2.17-5.227 0-9.473 1.657-12.74 4.97-3.22 3.313-4.83 7.513-4.83 12.6s1.61 9.287 4.83 12.6c3.267 3.313 7.513 4.97 12.74 4.97 3.08 0 5.88-.7 8.4-2.1 2.52-1.447 4.41-3.383 5.67-5.81l7 3.99c-2.053 3.593-4.97 6.463-8.75 8.61-3.733 2.1-7.84 3.15-12.32 3.15zM222.054 16.1h7.56v35h-7.56v-5.04c-2.846 3.967-6.93 5.95-12.25 5.95-4.806 0-8.913-1.773-12.32-5.32-3.406-3.593-5.11-7.957-5.11-13.09 0-5.18 1.704-9.543 5.11-13.09 3.407-3.547 7.514-5.32 12.32-5.32 5.32 0 9.404 1.96 12.25 5.88V16.1zm-18.97 25.55c2.1 2.1 4.737 3.15 7.91 3.15 3.174 0 5.81-1.05 7.91-3.15 2.1-2.147 3.15-4.83 3.15-8.05 0-3.22-1.05-5.88-3.15-7.98-2.1-2.147-4.736-3.22-7.91-3.22-3.173 0-5.81 1.073-7.91 3.22-2.1 2.1-3.15 4.76-3.15 7.98 0 3.22 1.05 5.903 3.15 8.05zM238.426 51.1V0h7.56v51.1h-7.56zM254.764 51.1V0h7.56v51.1h-7.56z"
    />
  </svg>
</template>
