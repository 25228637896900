import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleLivecall = _resolveComponent("TitleLivecall")!
  const _component_TitleLivecast = _resolveComponent("TitleLivecast")!
  const _component_TitleLiveconf = _resolveComponent("TitleLiveconf")!

  return (_ctx.iconType.indexOf('livecall') !== -1)
    ? (_openBlock(), _createBlock(_component_TitleLivecall, { key: 0 }))
    : (_ctx.iconType.indexOf('livecast') !== -1)
      ? (_openBlock(), _createBlock(_component_TitleLivecast, { key: 1 }))
      : (_openBlock(), _createBlock(_component_TitleLiveconf, { key: 2 }))
}